nav {
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.02);
  z-index: 1;
  font-family: var(--heading);
}

.logo img {
  height: 40px;
  width: auto;
}

.menu {
  display: flex;
  justify-content: space-evenly;
}

.menu li a {
  height: 40px;
  margin: 3px;
  padding: 5px 22px;
  border-radius: 5px;
  color: grey;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9em;
}

.menu li a:hover {
  background-color: var(--purple);
  color: white;
}
