:root {
  --heading: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --body: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --purple: green;
}

*,
::before,
::after {
  box-sizing: bounding-box;
}

img,
a {
  text-decoration: none;
  outline-style: none;
}

ul {
  list-style: none;
}

body,
main {
  background-color: rgba(0, 0, 0, 0.02);
  margin: 0px;
  font-family: var(--body);
}

h1 {
  font-family: var(--heading);
  font-size: 24px;
}

p {
  font-family: var(--body);
  font-size: 18px;
}
