#features {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--purple);
    padding: 48px 39px;
}

#features h1 {
    font-family: var(--heading);
    color: white;
}

#features ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 36px;
}

#features .card {
    min-width: 280px;
    width: 20vw;
    margin: 0px 10px;
    padding: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card img {
    margin: 48px 0px 18px 0px;
    width: 72px;
}

.card h2 {
    font-family: var(--heading);
    font-size: 20px;
    font-weight: 600;
    color: var(--purple);
}

.card p {
    width: 85%;
    font-size: 14px;
    color: grey;
    text-align: center;
    margin-bottom: 64px;
}